import { css } from "lit";

import { COLOR, TEXT, MEDIA } from "@idme/component-utilities";

const mobileArticleStyles = css`
  gap: 0 1rem;
  grid-template-areas:
    "icon title title"
    "icon cta cta";
  place-content: flex-start;
  text-align: left;
`;

export const styles = css`
  article {
    display: grid;
    grid-template-areas: "icon title description cta";
    gap: 1em;
    place-content: center;
    align-items: center;
    color: var(--idme-color-ribbon-text);
  }

  ::slotted(*) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ::slotted([slot="icon"]) {
    overflow: visible;
    display: block;
    font-size: 1.5rem !important;
    text-shadow:
      0 0 1px ${COLOR.white},
      -1px -1px 1px ${COLOR.white},
      -1px 1px 1px ${COLOR.white},
      1px 1px 1px ${COLOR.white},
      1px -1px 1px ${COLOR.white};
    grid-area: icon;
  }

  ::slotted([slot="title"]) {
    font-weight: ${TEXT.weight.bold} !important;
    color: var(--idme-color-ribbon-text) !important;
    font-family: ${TEXT.poppins} !important;
    font-size: 1em !important;
    margin: 0 !important;
    grid-area: title;
  }

  ::slotted([slot="description"]) {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 1em !important;
    grid-area: description;
  }

  ::slotted(a[slot="cta"]) {
    font-weight: ${TEXT.weight.semibold} !important;
    text-decoration: underline !important;
    color: inherit !important;
    grid-area: cta;
  }

  :host(.mobile) article {
    ${mobileArticleStyles}
  }
  :host(.mobile) ::slotted([slot="description"]) {
    display: none;
  }

  @media screen and (max-width: ${MEDIA.size.xs}) {
    article {
      ${mobileArticleStyles}
    }
    ::slotted([slot="description"]) {
      display: none;
    }
  }
`;
